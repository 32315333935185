import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineDot,
	TimelineItem,
	TimelineSeparator,
} from '@mui/lab';
import AffiliateTimeLinePaper from '@components/AffiliateTimelinePaper';
import { useMediaQuery } from '@mui/material';
import { SvgIcon } from '@mui/material';
import Signature from '@assets/signature.inline.svg';
import Share from '@assets/share.inline.svg';
import Money from '@assets/money.inline.svg';

const AffiliateTimeline: React.FC = () => {
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<div css={styles(theme)}>
			<Timeline align={matches ? 'left' : 'alternate'}>
				<TimelineItem>
					<TimelineSeparator>
						<TimelineDot className="timeline-dot">
							<SvgIcon>
								<Signature className="timeline-svg" />
							</SvgIcon>
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<AffiliateTimeLinePaper
							title="Join the community."
							description="Sign up to join our community. You don’t even to purchase a card to get started."
						/>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineSeparator>
						<TimelineDot>
							<SvgIcon>
								<Share className="timeline-svg" />
							</SvgIcon>
						</TimelineDot>
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent>
						<AffiliateTimeLinePaper
							title="Share and Promote."
							description={`Using your personalised link that is generated on your profile, you can send or personally invite users to Wave Card.`}
						/>
					</TimelineContent>
				</TimelineItem>
				<TimelineItem>
					<TimelineSeparator>
						<TimelineDot>
							<SvgIcon>
								<Money className="timeline-svg" />
							</SvgIcon>
						</TimelineDot>
					</TimelineSeparator>
					<TimelineContent>
						<AffiliateTimeLinePaper
							title="Get your profit."
							description="When you have successfully referred 5 people, you can withdraw your earning to any connected bank account."
						/>
					</TimelineContent>
				</TimelineItem>
			</Timeline>
		</div>
	);
};

export default AffiliateTimeline;
