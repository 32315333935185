import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.padded-container {
		height: 100%;
		overflow: hidden;
		//aspect-ratio: 16/9;
		.image {
			width: 100%;
			height: auto;
		}
		.centered {
			height: 100%;
			object-fit: cover;
		}
	}

	.testimonial-background {
		background-color: #161c24;
	}

	.text-container {
		padding-left: ${theme.spacing(3)};
		color: ${theme.palette.common.white};
	}
`;
