import React from 'react';
import { Paper, Typography } from '@mui/material';
import styles from './styles';
import { useTheme } from '@mui/material';

const AffiliateTimeLinePaper: React.FC<{ title: string; description: string }> = ({
	title,
	description,
}) => {
	const theme = useTheme();
	return (
		<div css={styles(theme)}>
			<Paper className="paper">
				<Typography variant="h3" gutterBottom>
					{title}
				</Typography>
				<Typography variant="body1" component="p">
					{description}
				</Typography>
			</Paper>
		</div>
	);
};

export default AffiliateTimeLinePaper;
