import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Box, Button, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'gatsby';

const AffiliateJoinCTA: React.FC<{
	backgroundImage?: string;
	backgroundContent?: string;
	description?: string;
	buttonText?: string;
	buttonLink?: string;
}> = ({ backgroundImage, backgroundContent, description, buttonText, buttonLink }) => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<div css={styles(theme, backgroundImage)}>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid item lg={6}>
					<img src={backgroundContent} className="image" />
				</Grid>
				<Grid item lg={6}>
					<Stack direction="column" spacing={mobile ? 5 : 3}>
						<Typography
							variant={mobile ? 'h2' : 'h1'}
							component="h2"
							className="cta-title"
						>
							{description}
						</Typography>
						<div>
							<Button
								variant="inversed"
								color="primary"
								component={Link}
								to={`/${buttonLink ?? ''}`}
								disableElevation
							>
								{buttonText}
							</Button>
						</div>
					</Stack>
				</Grid>
			</Grid>

			{/*<img src={backgroundContent} className="cta-image-hard-light" />*/}
		</div>
	);
};

export default AffiliateJoinCTA;
