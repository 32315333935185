import React from 'react';
import Layout from '@components/common/Layouts/Landing';
import AffiliateJoin from '@components/AffiliateJoin';
import { Box, Container } from '@mui/material';
import AffiliateTimeline from '@components/AffiliateTimeline';
import LandingHeader from '@components/LandingHeader';
import AffiliateUSPSection from '@components/AffiliateUSPSection';
import AffiliateJoinCTA from '@components/AffiliateJoinCTA';
import { useTheme } from '@mui/styles';
import styles from './styles';
import Hero from '@assets/hero.png';
import CTABackground from '@assets/cta-bg.png';
import CTAContent from '@assets/cta-phone-card.png';
import AffiliateTestimonial from '@components/AffiliateTestimonial';
import PersonSmiling from '@assets/affiliate-woman@2x.png';

const Affiliate: React.FC = () => {
	const theme = useTheme();

	return (
		<Layout>
			<div css={styles(theme)}>
				<LandingHeader
					title="Affiliate Program"
					backgroundImage={Hero}
					buttonText="UPGRADE FOR FREE"
					button
				/>
				<Container maxWidth="lg" sx={{ mt: 10 }}>
					<AffiliateJoin
						caption={`REWARDING THOSE WHO LOVE WAVE CARD`}
						title="Join our Affiliate program for up to a 25% commission for any sales you generate."
						description={`At Wave Card we want to show that our card is the highest quality business card that you can buy, with a platform that is quick and enjoyable to use. This is why we think a generous commission of up to 25% of any Wave Card related sale will reward our most loyal fans with ease.`}
						buttonText="join now"
					/>
					<Box mt={15}>
						<AffiliateTimeline />
					</Box>
					<Box mt={20} sx={{ mt: { xs: 10 } }}>
						<AffiliateUSPSection />
					</Box>
				</Container>
				<Box mt={20} sx={{ mt: { xs: 10 } }}>
					<AffiliateTestimonial
						backgroundImage={PersonSmiling}
						quote="“Wave Card not only helped me secure multiple clients but has paid for itself by giving me the tools to easily involve my industry using their affiliate program”"
						reference="Jane, Owner of TrustPilot"
					/>
				</Box>
				<Box mt={20} sx={{ mt: { xs: 10 } }}>
					<Container maxWidth="lg" sx={{ mb: 10 }}>
						<AffiliateJoinCTA
							backgroundImage={CTABackground}
							backgroundContent={CTAContent}
							description="Start earning from your network in more way than one."
							buttonText="JOIN"
						/>
					</Container>
				</Box>
			</div>
		</Layout>
	);
};

export default Affiliate;
