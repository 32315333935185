import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Button, Typography } from '@mui/material';
import { Link } from 'gatsby';

const LandingHeader: React.FC<{
	backgroundImage?: string;
	title?: string;
	button?: boolean;
	buttonText?: string;
	buttonLink?: string;
}> = ({ backgroundImage, title, button, buttonText, buttonLink, children }) => {
	const theme = useTheme();

	return (
		<div
			css={styles(theme)}
			style={{
				backgroundImage: `url(${backgroundImage})`,
			}}
		>
			<Typography variant="h1" className="hero-title" align="center">
				{title}
			</Typography>
			{button ? (
				<Button
					variant="contained"
					color="primary"
					component={Link}
					to={`/${buttonLink}`}
					disableElevation
					className="hero-button"
					sx={{ p: 2 }}
					style={{ width: '260px' }}
				>
					{buttonText}
				</Button>
			) : null}
			{children}
		</div>
	);
};

export default LandingHeader;
