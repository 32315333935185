import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.MuiTimelineConnector-root {
		border-style: dashed;
		background-color: transparent;
		border-color: #e8e8e8;
	}
	.MuiTimelineDot-root {
		padding: 20px;
		background-color: transparent;
		box-shadow: 0px 3px 15px #0000000d;
	}
	.timeline-svg {
		font-size: 27px;
		color: ${theme.palette.primary.main};
	}

	.MuiTimelineItem-missingOppositeContent:before {
		${theme.breakpoints.down('sm')} {
			display: none;
		}
	}
`;
