import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	min-height: 500px;
	height: 650px;
	position: relative;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		mix-blend-mode: color-burn;
		background-color: #2962ff;
		z-index: 200;
	}
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #00b6fb;
		z-index: 100;
		opacity: 0.8;
	}

	.hero-title {
		position: absolute;
		top: 150px;
		left: 50%;
		width: 100%;
		transform: translate(-50%, 0);
		z-index: 1000;
		color: #fff;
	}
	.hero-button {
		position: absolute;
		top: 335px;
		left: 50%;
		transform: translate(-50%, 0);
		z-index: 1000;
		color: #fff;
	}
`;
