import React from 'react';
import styles from './styles';
import { useTheme } from '@mui/material';
import { Button, Typography } from '@mui/material';
import { Link } from 'gatsby';

const AffiliateJoin: React.FC<{
	caption?: string;
	title?: string;
	description?: string;
	buttonText?: string;
	buttonLink?: string;
}> = ({ caption, title, description, buttonText, buttonLink }) => {
	const theme = useTheme();
	return (
		<div css={styles(theme)}>
			<Typography variant="caption" display="block" sx={{ color: '#2962FF', mb: 2 }}>
				{caption}
			</Typography>
			<Typography variant="h2" display="block" sx={{ mb: 5 }}>
				{title}
			</Typography>
			<Typography variant="body1" display="block" sx={{ mb: 5 }}>
				{description}
			</Typography>
			<Button
				variant="contained"
				color="primary"
				component={Link}
				to={`/${buttonLink}`}
				disableElevation
				sx={{ p: 2 }}
				style={{ width: '260px' }}
			>
				{buttonText}
			</Button>
		</div>
	);
};

export default AffiliateJoin;
