import React from 'react';

import styles from './styles';
import { useTheme } from '@mui/material';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

const AffiliateTestimonial: React.FC<{
	backgroundImage?: string;
	quote?: string;
	reference?: string;
}> = ({ backgroundImage, quote, reference }) => {
	const theme = useTheme();

	return (
		<div css={styles(theme)}>
			<Grid container spacing={1}>
				<Grid item xs={12} md={6}>
					<div className="padded-container">
						<img src={backgroundImage} className="image centered" />
					</div>
				</Grid>

				<Grid item xs={12} md={6} className="testimonial-background">
					<Grid container alignItems="center" sx={{ height: '100%' }}>
						<Grid item xs={8}>
							<Box
								className="text-container"
								sx={{ py: { xs: 8 }, paddingLeft: { xs: 4 } }}
							>
								<Typography
									variant="body1"
									sx={{
										marginBottom: '1.7em',
										fontWeight: theme.typography.fontWeightBold,
									}}
								>
									{quote}
								</Typography>
								<Typography variant="subtitle2">{reference}</Typography>
							</Box>
						</Grid>
						<Grid item xs={4}></Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};

export default AffiliateTestimonial;
