import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme, backgroundImage) => css`
	max-width: 100%;
	max-height: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: ${theme.shape.borderRadiusMd}px;
	background-image: url(${backgroundImage});
	padding: ${theme.spacing(4, 8)};
	color: white;

	.image {
		max-width: 100%;
		max-height: 100%;
	}
`;
