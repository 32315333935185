import { css } from '@emotion/react';
import { Theme } from '@mui/material';

export default (theme: Theme) => css`
	.paper {
		padding: 16px 20px;
		text-align: left;
		box-shadow: 2px 3px 20px #00000029;
	}
`;
