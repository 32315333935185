import React, { ReactNode } from 'react';
import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	CardMedia,
	Stack,
	SvgIcon,
	Typography,
} from '@mui/material';
import styles from '@components/AffiliateTimeline/styles';
import { useTheme } from '@mui/material';

const USPCard: React.FC<{ svg?: ReactNode; title?: string; description?: string }> = ({
	svg,
	title,
	description,
}) => {
	const theme = useTheme();
	return (
		<div css={styles(theme)}>
			<Box sx={{ height: 200 }}>
				<Stack
					direction="column"
					spacing={3}
					alignItems="center"
					sx={{ maxWidth: '500px', margin: 'auto' }}
				>
					<Box
						className="cta-wrapper"
						sx={{
							borderRadius: '50%',
							height: 75,
							width: 75,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							alignSelf: 'center',
							boxShadow: '0px 3px 15px #0000000d',
						}}
					>
						{svg}
					</Box>

					<Typography variant="h3" component="h3">
						{title}
					</Typography>
					<Typography variant="body2" align="center">
						{description}
					</Typography>
				</Stack>
			</Box>
		</div>
	);
};

export default USPCard;
